import env from '@beam-australia/react-env'

const configService = {
    validateConfig: (): void => {
        configService.getAuth0BaseUrl()
        configService.getAuth0ClientId()
        configService.getBackendHostname()
        configService.getApiTimeout()
    },
    getAuth0BaseUrl: (): string => {
        if (!env('AUTH0_URL')) {
            throw new Error('Missing env variable "REACT_APP_AUTH0_URL".')
        }

        return env('AUTH0_URL')
    },
    getAuth0ClientId: (): string => {
        if (!env('AUTH0_CLIENT_ID')) {
            throw new Error('Missing env variable "REACT_APP_AUTH0_CLIENT_ID".')
        }

        return env('AUTH0_CLIENT_ID')
    },
    getBackendHostname: (): string => {
        if (!env('BACKEND_HOSTNAME')) {
            throw new Error('Missing env variable "REACT_APP_BACKEND_HOSTNAME".')
        }

        return env('BACKEND_HOSTNAME')
    },
    getApiTimeout: (): number => {
        if (!env('API_TIMEOUT')) {
            throw new Error(
                'Missing env variable "REACT_APP_API_TIMEOUT". Please check with sys-admin. Timeout is needed!',
            )
        }

        const timeout = parseInt(env('API_TIMEOUT'), 10)

        if (timeout < 1000) {
            throw new Error(
                `Please provde a number greater than 1000. Given timeout of "${timeout}" is invalid, aborting`,
            )
        }

        return timeout
    },
    getAuth0RedirectUrl: (): string => {
        return `http://${window.location.host.toString()}`
    },
    getLoginPath: (): string => {
        if (!env('PATH_LOGIN')) {
            throw new Error('Missing env variable "PATH_LOGIN".')
        }

        return env('PATH_LOGIN')
    },
    getVerificationPath: (): string => {
        if (!env('PATH_VERIFICATION')) {
            throw new Error('Missing env variable "PATH_VERIFICATION".')
        }

        return env('PATH_VERIFICATION')
    },
}

export default configService
