import * as React from 'react'
import { Container, Jumbotron } from 'react-bootstrap'
import NavigationBar from 'components/NavigationBar'

const Landingpage = (): JSX.Element => {
    return (
        <>
            <NavigationBar isLoggedIn={false} />

            <Jumbotron fluid>
                <Container>
                    <h1 data-testid="headline">Feedelta ist super!</h1>
                </Container>
            </Jumbotron>
        </>
    )
}

export default Landingpage
